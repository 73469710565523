import React from 'react'
import imgLogo from '../../images/logo.png'

const About = () => (
  <div className="section" id="about">
    <div className="container">
      <div className="card" data-aos="fade-up" data-aos-offset="10">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <img
              src={imgLogo}
              alt=""
              style={{ display: `block`, margin: `auto auto`, paddingTop: `20px` }}
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="card-body">
              <div className="h4 mt-0 title">About</div>
              <p>
                Hello! I am Jamel. Freelance Developer. I am specialized in Microsoft technologies
                (.NET, ASP, C#, Azure, Azure DevOps...). I also have a lot of experience in
                JavaScript.
              </p>
              <p>
                {' '}
                I've been coding for almost 10 years and I am very passionate about new
                technologies. Scroll down to learn more about me.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="card-body">
              <div className="h4 mt-0 title">Basic Information</div>
              <div className="row mt-3">
                <div className="col-sm-4">
                  <strong className="text-uppercase">Experience:</strong>
                </div>
                <div className="col-sm-8">9 years</div>
              </div>
              <div className="row mt-3" style={{ display: `none` }}>
                <div className="col-sm-4">
                  <strong className="text-uppercase">Phone:</strong>
                </div>
                <div className="col-sm-8" />
              </div>
              <div className="row mt-3">
                <div className="col-sm-4">
                  <strong className="text-uppercase">Location:</strong>
                </div>
                <div className="col-sm-8">Toulouse, France</div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-4">
                  <strong className="text-uppercase">Language:</strong>
                </div>
                <div className="col-sm-8">French, English</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default About
