import React from 'react'
import Progress from '../progress'
import imgDotnet from '../../images/dotnet.svg'
import imgJavascript from '../../images/javascript.svg'
import imgCsharp from '../../images/csharp.svg'
import imgReact from '../../images/react.svg'
import imgXaml from '../../images/xaml.png'
import imgHtml5 from '../../images/html5.svg'
import imgWpf from '../../images/wpf.png'
import imgCss3 from '../../images/css3.png'
import imgWindows81 from '../../images/windows81.png'
import imgSql from '../../images/sql.png'
import imgWindowsPhone from '../../images/windowsphone.svg'
import imgCordova from '../../images/cordova.png'
import imgAspMvc from '../../images/aspnetmvc.png'
import imgNodejs from '../../images/nodejs.png'
import imgDotnetCore from '../../images/dotnetcore.svg'
import imgAngular from '../../images/angular.svg'
import imgMldotnet from '../../images/mldotnet.svg'
import imgAzureDevops from '../../images/azuredevops.svg'
import imgBlockchain from '../../images/blockchain.svg'
import imgVisulStudio from '../../images/vs.svg'
import imgVisulStudioCode from '../../images/vscode.svg'
import imgSqlServer from '../../images/sqlserver.svg'
import imgMysql from '../../images/mysql.png'
import imgGatsby from '../../images/gatsby.png'
import imgElectron from '../../images/electron.gif'
import imgShopify from '../../images/shopify.png'
import imgGithub from '../../images/github.svg'

const Skills = () => (
  <div className="section" id="skill">
    <div className="container">
      <div className="h4 text-center mb-4 title">Professional Skills</div>
      <div
        className="card aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="card-body">
          <div className="row">
            <Progress logo={imgDotnet} title=".NET" number={100} />
            <Progress logo={imgJavascript} title="JavaScript" number={100} />
            <Progress logo={imgCsharp} title="C#" number={100} />
            <Progress logo={imgReact} title="React" number={100} />
            <Progress logo={imgXaml} title="XAML" number={100} />
            <Progress logo={imgHtml5} title="HTML" number={100} />
            <Progress logo={imgWpf} title="WPF" number={100} />
            <Progress logo={imgCss3} title="CSS" number={80} />
            <Progress logo={imgWindows81} title="Windows 8.1" number={100} />
            <Progress logo={imgSql} title="SQL" number={80} />
            <Progress logo={imgWindowsPhone} title="Windows Phone 8" number={100} />
            <Progress logo={imgCordova} title="Cordova" number={80} />
            <Progress logo={imgAspMvc} title="ASP .NET MVC" number={100} />
            <Progress logo={imgNodejs} title="NodeJS" number={50} />
            <Progress logo={imgDotnetCore} title="ASP .NET Core" number={80} />
            <Progress logo={imgAngular} title="AngularJS v1.4" number={100} />
            <Progress logo={imgDotnet} title="Entity Framework" number={80} />
            <Progress logo={imgMldotnet} title="AI &amp; ML" number={10} />
            <Progress logo={imgAzureDevops} title="Azure DevOps" number={50} />
            <Progress logo={imgBlockchain} title="Blockchain" number={10} />
            <Progress logo={imgVisulStudio} title="Visual Studio" number={100} />
            <Progress logo={imgVisulStudioCode} title="Visual Studio Code" number={100} />
            <Progress logo={imgSqlServer} title="SQL Server" number={50} />
            <Progress logo={imgMysql} title="MySQL" number={50} />
            <Progress logo={imgGatsby} title="GatsbyJS" />
            <Progress logo={imgElectron} title="Electron" />
            <Progress logo={imgShopify} title="Shopify" />
            <Progress logo={imgGithub} title="Github" />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Skills
