import React from 'react'
import * as axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'

export default class Contact extends React.Component {
  constructor() {
    super()
    this.state = {
      email: '',
      message: '',
      submitted: false,
      error: undefined,
      recaptchaRef: React.createRef(),
    }
  }

  onSubmit = async () => {
    const { email, message, recaptchaRef } = this.state
    this.setState({ submitted: true })

    try {
      const token = await recaptchaRef.current.executeAsync()

      await axios({
        method: 'POST',
        url: `/.netlify/functions/contact`,
        data: JSON.stringify({ email, message, token }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      this.setState({ error: '' })
    } catch (err) {
      console.error(err)
      this.setState({ error: err })
      recaptchaRef.current.reset()
    }
  }

  render() {
    return (
      <section className="section" id="contact">
        {/* <ReCAPTCHA
          sitekey={`6LfOn90ZAAAAAH89BhUpmqF5wsKDgmQCCQt4xfle`}
          size="invisible"
          ref={this.state.recaptchaRef}
        /> */}
        <div className="cc-contact-information">
          <div className="container">
            <div className="cc-contact">
              <div className="row">
                <div className="col-md-9">
                  <div className="card mb-0 aos-init aos-animate" data-aos="zoom-in">
                    <div className="h4 text-center title">Contact Me</div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card-body">
                          {/* {this.state.submitted ? this.renderAfterSubmit() : this.renderForm()} */}
                          {this.renderContactViaLinkedin()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  renderForm() {
    return (
      <form>
        <div className="row mb-3">
          <div className="col">
            <div className="input-group">
              <span className="input-group-addon">
                <i className="fa fa-envelope" />
              </span>
              <input
                className="form-control"
                type="email"
                name="email"
                placeholder="E-mail"
                required="required"
                onChange={event => {
                  this.setState({ email: event.currentTarget.value })
                }}
              />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <div className="form-group">
              <textarea
                className="form-control"
                style={{ lineHeight: 5 }}
                name="message"
                placeholder="Your Message"
                required="required"
                onChange={event => {
                  this.setState({ message: event.currentTarget.value })
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button className="btn btn-primary" onClick={this.onSubmit}>
              Send
            </button>
          </div>
        </div>
      </form>
    )
  }

  renderAfterSubmit() {
    const message =
      this.state.error === undefined
        ? 'sending...'
        : this.state.error === ''
        ? 'Thank you for contacting me. I will get back to you as soon as possible.'
        : 'Sorry your email could not be sent, please try again later.'
    return (
      <div className="p pb-3">
        <strong>{message}</strong>
      </div>
    )
  }

  renderContactViaLinkedin() {
    return (
      <div className="p pb-3">
        <strong>
          Please contact me via my{' '}
          <a className="" href="https://www.linkedin.com/in/jait">
            LinkedIn profile
          </a>
          . Thanks.
        </strong>
      </div>
    )
  }
}
