import PropTypes from 'prop-types'
import React from 'react'

const Degree = ({ date, degree, degree2, title, school, logo }) => (
  <div className="card">
    <div className="row">
      <div
        className="col-md-3 bg-primary aos-init"
        data-aos="fade-right"
        data-aos-offset="50"
        data-aos-duration="500"
      >
        <div className="card-body cc-education-header">
          <p>{date}</p>
          <div className="h5">{degree}</div>
          <div className="h5">{degree2}</div>
        </div>
      </div>
      <div
        className="col-md-9 aos-init"
        data-aos="fade-left"
        data-aos-offset="50"
        data-aos-duration="500"
      >
        <div className="card-body">
          <div className="h5">{title}</div>
          <p className="category">{school}</p>
          {logo ? <img style={{ marginTop: `15px` }} src={logo} alt={title} height="60" /> : null}
        </div>
      </div>
    </div>
  </div>
)

Degree.propTypes = {
  date: PropTypes.string,
  degree: PropTypes.string,
  title: PropTypes.string,
  school: PropTypes.string,
}

export default Degree
