/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Header from './header'
import About from '../components/sections/about'
import Skills from '../components/sections/skills'
import Badges from '../components/sections/badges'
import Experience from '../components/sections/experience'
import Education from '../components/sections/education'
import Contact from '../components/sections/contact'

// import '../js/jquery.3.2.1.min.js'
// import 'jquery'

// import jquery from 'jquery/jquery.js'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap/dist/js/bootstrap.js'
import gatsy from '../images/gatsby.png'
import netlify from '../images/netlify.png'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        {/* <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        > */}
        <div>
          <main>{children}</main>
          <About />
          <Skills />
          <Badges />
          <Experience />
          <Education />
          <Contact />

          <footer className="footer">
            <div className="container text-center">
              <a className="cc-linkedin btn btn-link" href="https://www.linkedin.com/in/jait">
                <i className="fa fa-linkedin fa-2x " aria-hidden="true" />
              </a>
              <a className="cc-linkedin btn btn-link" href="https://github.com/jamelait">
                <i className="fa fa-github fa-2x " aria-hidden="true" />
              </a>
              {/* <a className="cc-linkedin btn btn-link" href="https://medium.com/@jamelait">
                <i className="fa fa-medium fa-2x " aria-hidden="true" />
              </a> */}
              <div className="h4 title text-center">Jamel Aït Ouarraou</div>
              <div className="h5 text-center">
                <p>
                  <br />
                  Powered by
                  <br />
                  <img height="50" src={gatsy} alt="gatsby" />
                  {'   '}
                  and{'   '}
                  <img height="50" src={netlify} alt="netlify" />
                </p>
              </div>
            </div>
          </footer>
          {/* <footer className="footer">
            <a
              className="cc-linkedin btn btn-link"
              href="https://www.linkedin.com/in/jait"
            >
              <i className="fa fa-linkedin fa-2x " aria-hidden="true" />
            </a>
            <a className="cc-linkedin btn btn-link" href="https://github.com/jamelait">
              <i className="fa fa-github fa-2x " aria-hidden="true" />
            </a>
            <a className="cc-linkedin btn btn-link" href="https://medium.com/@jamelait">
              <i className="fa fa-medium fa-2x " aria-hidden="true" />
            </a>
            <div className="h4 title text-center">Jamel Aït Ouarraou</div>©{' '}
            {new Date().getFullYear()}, Built with {` `}{' '}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
            
          </footer> */}
          <Helmet>
            {/* <script
              language="JavaScript"
              type="text/javascript"
              src="js/core/jquery.3.2.1.min.js"
            />
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Montserrat:400,700,200"
            />
            <link
              href="https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css"
              rel="stylesheet"
            />
            <link
              href="https://stackpath.bootstrapcdn.com/bootstrap/4.0.0-beta/css/bootstrap.min.css"
              rel="stylesheet"
            />
            <link href="stylesheets/aos.css" rel="stylesheet" />
            <link href="stylesheets/main.css" rel="stylesheet" />
            <script type="text/javascript" src="js/core/bootstrap.min.js" />
            <script type="text/javascript" src="js/core/popper.min.js" />
            <script type="text/javascript" src="js/now-ui-kit.js" />
            <script type="text/javascript" src="js/aos.js" />
            <script type="text/javascript" src="js/main.js" /> */}
          </Helmet>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
