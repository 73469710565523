import React from 'react'
import Degree from '../degree'
import imgSupinfo from '../../images/supinfo.png'
import imgIut from '../../images/iutmontreuil2010_logo.png'

const Education = () => (
  <section className="section" id="education">
    <div className="container cc-education">
      <div className="h4 text-center mb-4 title">Education</div>

      <Degree
        date="2014"
        degree="Master's Degree"
        degree2="Master Bac+5"
        title="Master of Computer Science"
        school="SUPINFO - The International Institute of Information Technology"
        logo={imgSupinfo}
      />

      <Degree
        date="2010"
        degree="DUT Bac+2"
        title="Higher National Diploma in Technology - Computer Science"
        school="IUT de Montreuil"
        logo={imgIut}
      />
    </div>
  </section>
)

export default Education
