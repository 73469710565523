// import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Header = ({ siteTitle }) => (
  <header>
    <div className="profile-page sidebar-collapse">
      <nav
        className="navbar navbar-expand-lg fixed-top navbar-transparent bg-primary"
        color-on-scroll="400"
      >
        <div className="container">
          <div className="navbar-translate">
            <a className="navbar-brand" href="/#" rel="tooltip">
              JAMLABS
            </a>
            <button
              className="navbar-toggler navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navigation"
              aria-controls="navigation"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <div id="navigation" className="collapse navbar-collapse justify-content-end">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link smooth-scroll" href="#about">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link smooth-scroll" href="#skill">
                  Skills
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link smooth-scroll" href="#experience">
                  Experience
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link smooth-scroll" href="#education">
                  Education
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link smooth-scroll" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
