import PropTypes from 'prop-types'
import React from 'react'

const Progress = ({ logo, title, number }) => (
  <div className="col-md-6">
    <div className="progress-container progress-primary">
      {logo ? (
        <img src={logo} height="30" width="30" alt="js" style={{ marginRight: `5px` }} />
      ) : null}
      <span className="progress-badge">{title}</span>
      <div className="progress">
        <div
          className="progress-bar progress-bar-primary aos-init aos-animate"
          data-aos="progress-full"
          data-aos-offset="10"
          data-aos-duration="2000"
          role="progressbar"
          aria-valuenow={number}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: number + '%' }}
        />
        <span className="progress-value">{number + '%'}</span>
      </div>
    </div>
  </div>
)

Progress.propTypes = {
  title: PropTypes.string,
  number: PropTypes.number,
}

Progress.defaultProps = {
  number: 100,
}

export default Progress
