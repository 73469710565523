import React from 'react'
import Badge from '../badge'

import img483 from '../../images/exam-483-programming-in-c.png'
import img486 from '../../images/exam-486-developing-asp-net-mvc-web-applications.png'
import img487 from '../../images/exam-487-developing-microsoft-azure-and-web-services.png'
import img480 from '../../images/exam-480-programming-in-html5-with-javascript-and-css3.png'
import imgMcsa from '../../images/mcsa-web-applications-certified-2016.png'
import imgMcsd from '../../images/mcsd-app-builder-certified-2016.png'

const Badges = () => (
  <section className="section" id="badges">
    <div className="container">
      <div className="h4 text-center mb-4 title">Badges &amp; Certifications</div>

      <div className="card aos-init" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
        <div className="card-body">
          <div className="row">
            <Badge title="Exam 483: Programming in C#" subtitle="Microsoft" image={img483} />

            <Badge
              title="Exam 486: Developing ASP.NET MVC Web Applications"
              subtitle="Microsoft"
              image={img486}
            />
            <Badge
              title="Exam 487: Developing Microsoft Azure and Web Services"
              subtitle="Microsoft"
              image={img487}
            />
            <Badge
              title="Exam 480: Programming in HTML5 with JavaScript and CSS3"
              subtitle="Microsoft"
              image={img480}
            />
            <Badge
              title="MCSA: Web Applications - Certified 2016"
              subtitle="Microsoft"
              image={imgMcsa}
            />
            <Badge
              title="MCSD: App Builder — Certified 2016"
              subtitle="Microsoft"
              image={imgMcsd}
            />
          </div>
          <br />
          <p>
            You can see all my badges on the{' '}
            <a
              href="https://www.youracclaim.com/user/jamel-ait-ouarraou"
              target="_blank"
              rel="noopener noreferrer"
            >
              cclaim official website.
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default Badges
