import PropTypes from 'prop-types'
import React from 'react'
// import { PhotoSwipe } from 'react-photoswipe'
// import 'react-photoswipe/lib/photoswipe.css'

let key = 1
// const getThumbnailContent = item => {
//   return <img src={item.thumbnail} width={200} height={100} alt={item.title} />
// }

const Job = ({ logo, date, company, title, description, list, images }) => {
  // let items = (images || []).map(element => {
  //   return {
  //     src: element.src,
  //     thumbnail: element.src,
  //     w: 800,
  //     h: 463,
  //     title: element.caption,
  //   }
  // })
  return (
    <div className="card">
      <div className="row">
        <div
          className="col-md-3 bg-primary aos-init"
          data-aos="fade-right"
          data-aos-offset="50"
          data-aos-duration="500"
        >
          <div className="card-body cc-experience-header">
            <p>{date}</p>
            <div className="h5">{company}</div>
            {logo ? <img src={logo} alt={company} height="60" /> : null}
          </div>
        </div>
        <div
          className="col-md-9 aos-init"
          data-aos="fade-left"
          data-aos-offset="50"
          data-aos-duration="500"
        >
          <div className="card-body">
            <div className="h5">{title}</div>
            <p>{description}</p>
            <ul>
              {(list || []).map(element => {
                return <li key={key++}>{element}</li>
              })}
            </ul>
            <div>
              {(images || []).map(element => {
                return (
                  <a key={key++} href={element.src}>
                    <img src={element.src} alt={element.caption} width="200" />
                  </a>
                )
              })}
              {/* {images ? (
                <PhotoSwipe isOpen={true} items={items} thumbnailContent={getThumbnailContent} />
              ) : null} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Job.propTypes = {
  date: PropTypes.string,
  company: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  list: PropTypes.array,
  images: PropTypes.array,
}

export default Job
