import PropTypes from 'prop-types'
import React from 'react'

const Badge = ({ title, subtitle, image }) => (
  <>
    <div className="col-md-2">
      <img src={image} alt="" />
    </div>
    <div className="col-md-4">
      <p className="h4">{title}</p>
      <p>{subtitle}</p>
    </div>
  </>
)

Badge.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
}

export default Badge
