import React from 'react'
import Job from '../job'
import imgAvanade from '../../images/avanade.png'
import imgAirbus from '../../images/airbus.jpg'
import imgVinci from '../../images/vinci.jpg'
import imgEiffage from '../../images/eiffage.png'
import imgSodexo from '../../images/sodexo.jpg'
import imgGrdf from '../../images/grdf.png'
import imgEdfen from '../../images/edfen.png'
import imgSchneider from '../../images/schneider.png'
import imgFrmk from '../../images/frmk.png'
import imgPlanet from '../../images/planet.png'
import imgGeodis from '../../images/geodis.jpg'

const Experience = () => (
  <section className="section" id="experience">
    <div className="container cc-experience">
      <div className="h4 text-center mb-4 title">Work Experience</div>

      <Job
        date="April 2014 - April 2017"
        company="AVANADE"
        logo={imgAvanade}
        title="VARIOUS PROJECTS"
        list={['App development', '.NET', 'C#', 'ASP .NET MVC', 'Azure', 'SQL Server']}
      />

      <Job
        date="September 2016 - April 2017"
        company="AIRBUS"
        logo={imgAirbus}
        title="Digital Reporting"
        description="Single Page Application (SPA) allowing teams at Airbus to have an overview and detailed
view of materials used in the construction of planes."
        list={[
          'App development',
          'JavaScript',
          'React',
          '.NET',
          'C#',
          'Web API',
          'SCRUM',
          'Teamwork',
          'Interactions with UX and DB team',
        ]}
      />

      <Job
        date="January 2016 - April 2016"
        company="VINCI"
        logo={imgVinci}
        title="PDV"
        description="Web app used for certain calculation required by the purchase department."
        list={[
          'App development',
          'Interactions with end-users to formalize the requirements',
          'Presentation to end-users',
          'C#',
          'ASP .NET MVC 5',
          'JavaScript',
          'Entity Framework',
        ]}
      />

      <Job
        date="September 2015"
        company="Eiffage"
        logo={imgEiffage}
        description="WPF application"
        list={['C#', 'WPF']}
      />

      <Job
        date="July 2015 - September 2015"
        company="SODEXO"
        logo={imgSodexo}
        title="OPTIMUM FOOD MANAGEMENT"
        description="Web app integrated with Microsoft Dynamics AX, allowing users to see stocks and manage them."
        list={[
          'C#',
          'ASP .NET MVC 4',
          'JavaScript',
          'HTML, CSS, Bootstrap',
          'Interactions with Microsft Dynamics AX team',
        ]}
      />

      <Job
        date="January 2015 - June 2015"
        company="GRDF"
        logo={imgGrdf}
        title="PIXII / CIIGAZ / OMNI"
        description="PIXII, CiiGaz and OMNI are three applications that GRDF techniciens used on the ground during their various interventions. These apps were orginally on the Windows Mobile platform. Our mission consisted in migrating these apps to the Windows 8.1 platform."
        list={[
          'Windows 8.1 Framework',
          'SQL Server CE 3.5',
          '.NET 4.5',
          'C#',
          'XAML',
          'SCRUM',
          'Teamwork',
          'Interactions with UX team',
          'TFS',
          'Technical documentation',
        ]}
        images={[
          { src: '/images/grdf1.jpg' },
          { src: '/images/grdf2.jpg' },
          { src: '/images/grdf3.jpg' },
        ]}
      />

      <Job
        date="December 2014"
        company="EDF ENERGIES NOUVELLES"
        logo={imgEdfen}
        title="SAHARA"
        description="Intranet web app allowing workers to declare and track work incidents."
        list={[
          'C#',
          'ASP .NET MVC 5',
          'JavaScript',
          'Entity Framework',
          'Excel Reporting',
          'Technical documentation',
          'TFS',
        ]}
      />

      <Job
        date="June 2014 - November 2014"
        company="EDF ENERGIES NOUVELLES"
        logo={imgEdfen}
        title="WINDGA v2"
        description="Intranet web app used to manage the wind and solar power plant facilities."
        list={[
          'SCRUM',
          'TFS',
          'Mantis',
          'Interactions with UX',
          'Unit tests',
          'C#',
          'ASP .NET MVC 5',
          'JavaScript',
          'Entity Framework',
          'Excel Reporting',
          'Technical documentation',
        ]}
      />

      <Job
        date="May 2014"
        company="SCHNEIDER ELECTRIC"
        logo={imgSchneider}
        title="ECATALOGUE"
        description="Windows app meant to be used in showrooms to showcase the product catalogue by using the existing API to retrieve the data."
        list={['Windows 8.1 Framework', '.NET 4.5', 'C#', 'XAML', 'WCF']}
      />

      <Job
        date="January 2013 - January 2016"
        company="ROYAL MOROCCAN FEDERATION OF KARATE"
        logo={imgFrmk}
        title="TABLET"
        description="The Royal Moroccan Federation of Karate (FRMK) wanted to dematerialize the whole
        process of grading the candidates during the grade examination. The project
        consisted of 3 parts: a tablet application (Cordova), a desktop application (WPF)
        and a web API (WCF) that communicates with a MySQL server."
        list={[
          'App development',
          'Cordova',
          'AngularJS v1.4',
          'UX',
          '.NET',
          'C#',
          'WPF',
          'WCF',
          'NancyFx',
          'MySQL',
          'Visual Studio',
          'Android',
          'Genymotion',
          'SVN',
        ]}
        images={[
          {
            src: '/images/frmk3.jpg',
            caption: 'Jury using the tablets during the examination.',
          },
          {
            src: '/images/frmk4.jpg',
            caption: 'Presentation of the application to the Prince of Morocco.',
          },
        ]}
      />

      <Job
        date="June 2012 - August 2012"
        company="PLANET (FINTRAX - PREMIER TAX FREE)"
        logo={imgPlanet}
        title="VOUCHERS"
        description="Web app used to update the voucher numbers used by client stores."
        list={['App development', '.NET 4', 'C#', 'ASP .NET 4', 'SQL Server 2008']}
      />

      <Job
        date="September 2009 - August 2010"
        company="GEODIS LOGISTICS"
        logo={imgGeodis}
        title="ACTIVITY TRACKING"
        description="Web app used by employees to track the time spent on interventions at client's."
        list={['C#', 'ASP .NET WebForms', 'SQL Server 2005']}
      />
    </div>
  </section>
)

export default Experience
